<template>
  <ion-page>   
    <ion-content :fullscreen="true">

      <Header></Header>
        
      <div class="container">
        <section id="options" class="list-nav mt-lg mb-md">
          <ion-grid>
            <ion-row>
              <ion-col size="12" size-lg="6">
                <h4>Empresa</h4>
                <h1>Mi Perfil</h1>
              </ion-col>
            </ion-row>
            <ion-row style="margin-top: 1rem">
              <ion-col size="12" size-lg="6">
                <div class="flex">
                  <ion-button @click="exit" shape="round" color="dark" fill="outline">
                      <ion-icon slot="start" :icon="arrowBackOutline"></ion-icon>
                      <ion-label>Volver</ion-label>
                  </ion-button>
                  <ion-button @click="save" color="primary" style="margin-left:0.75rem" shape="round">
                      <ion-icon slot="start" :icon="saveOutline"></ion-icon>
                      <ion-label>Guardar</ion-label>
                  </ion-button>
                </div>
              </ion-col>
              <ion-col size="12" size-lg="6">
                <ion-buttons class="options-nav">
                  <ion-button @click="changePassword">
                    <ion-icon slot="start" :icon="lockClosedOutline"></ion-icon>
                    <ion-label color="dark">Cambiar Contraseña</ion-label>
                  </ion-button>
                  <ion-button @click="logout">
                    <ion-icon slot="start" color="danger" :icon="logOutOutline"></ion-icon>
                    <ion-label color="danger">Cerrar Sesión</ion-label>
                  </ion-button>
                </ion-buttons>
              </ion-col>
            </ion-row>
        </ion-grid>
        </section>

        <section id="content" v-if="evolbeUser">
          <ion-grid class="mb-lg">
            <ion-row>
              <ion-col size="12" size-lg="8">
                <ion-grid>
                    <ion-row class="mb-md">
                        <ion-col size="12">
                          <h5 class="edit-title">Perfil</h5>
                        </ion-col>

                        <ion-col size="12" size-lg="6" class="box-input">
                            <label for="name">Nombre</label>
                            <InputText class="input secondary-input" id="name" placeholder="Nombre" type="text" v-model.trim.lazy="evolbeUser.profile.firstName" />
                        </ion-col>
                        <ion-col size="12" size-lg="6" class="box-input">
                            <label for="lastName">Apellidos</label>
                            <InputText class="input" id="lastName" placeholder="Apellidos" type="text" v-model.trim.lazy="evolbeUser.profile.lastName" />
                        </ion-col>
                        <ion-col size="12" size-lg="6" class="box-input">
                            <label for="sex">Sexo</label>
                            <Dropdown v-model.lazy="evolbeUser.profile.sex" class="input secondary-input" id="sex" :options="sexos" optionLabel="name" optionValue="code" placeholder="Seleccionar" />
                        </ion-col>
                        <ion-col size="12" size-lg="6" class="box-input">
                            <label for="rol">Permisos</label>
                            <InputText class="input" id="rol" disabled placeholder="Rol" type="text" v-model.trim.lazy="evolbeUser.business[0].rol" />
                        </ion-col>
                        <ion-col size="12" class="box-input">
                            <label for="email">Email</label>
                            <InputText class="input" id="email" disabled placeholder="Email" type="email" v-model.trim.lazy="evolbeUser.profile.email" />
                        </ion-col>
                    </ion-row>

                    <ion-row class="mb-md">
                        <ion-col size="12">
                          <h5 class="edit-title">Impresión</h5>
                          <ion-list lines="none">
                            <ion-item style="padding-bottom: 1rem">
                              <ion-label text-wrap>Tipo de Impresora</ion-label>
                              <SelectButton slot="end" v-model="printType" :options="impresoras" @change="updateDevices" optionLabel="name" optionValue="value"/>
                            </ion-item>
                            <div v-if="printType === 'bluetooth'" style="padding-bottom: 1rem">
                                <ion-list lines="none" v-if="BTList.length">
                                  <ion-item v-for="device in BTList" :key="device.id">
                                      <ion-label>{{device.name}}</ion-label>
                                      <ion-button shape="round" slot="end" color="primary" @click="toggleConection(device)" v-if="!btPrinter || btPrinter.id !== device.id">
                                          <ion-label>Conectar</ion-label>
                                      </ion-button>
                                      <ion-button shape="round" slot="end" color="danger" @click="toggleConection(device)" v-if="btPrinter && btPrinter.id === device.id">
                                          <ion-label>Desconectar</ion-label>
                                      </ion-button>
                                  </ion-item>
                                </ion-list>
                                <p v-if="!BTList.length">No hay dispositivos disponibles.</p>
                                <h6 style="padding-top: 0.5rem" v-if="!BTList.length">Comprueba que el Bluetooth del dispositivo está encendido e inténtalo de nuevo.</h6>
                            </div>
                            <ion-item style="padding-bottom: 1rem">
                              <ion-label text-wrap>Tamaño del Papel</ion-label>
                              <SelectButton slot="end" v-model="paperSize" :options="tamano" optionLabel="name" optionValue="value"/>
                            </ion-item>
                          </ion-list>
                        </ion-col>
                    </ion-row>
                </ion-grid>
              </ion-col>
              <ion-col size="12" size-lg="4">
              </ion-col>
            </ion-row>
          </ion-grid>
        </section>
      </div>

      <Footer></Footer>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonButtons, IonButton, IonIcon, IonLabel, IonList, IonItem } from '@ionic/vue';
import { arrowBackOutline, saveOutline, trashOutline, lockClosedOutline, logOutOutline  } from 'ionicons/icons';

import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import SelectButton from 'primevue/selectbutton';
import {BluetoothSerial} from '@awesome-cordova-plugins/bluetooth-serial';

import { defineComponent } from 'vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

import { mapActions, mapState } from 'vuex';

export default defineComponent({
  name: 'Profile',
  components: {
    IonContent,
    IonPage,
    Header,
    IonGrid,
    IonRow,
    IonCol,
    Footer,
    Dropdown,
    InputText,
    IonButtons,
    IonButton,
    IonIcon,
    IonLabel,
    SelectButton,
    IonList,
    IonItem
  },
  data() {
    return {
      sexos: [
              {name: 'Masculino', code: 'm'},
              {name: 'Femenino', code: 'f'},
              {name: 'Otro', code: 'o'},
             ],
      impresoras: [
        {name: 'Bluetooth', value: 'bluetooth'},
        {name: 'USB / Wifi', value: 'other'}
      ],
      tamano: [
        {name: '80mm', value: '80mm'},
        {name: '56mm', value: '56mm'}
      ],

      BTList: [],
      printType: 'other',
      paperSize: '80mm',
      btPrinter: undefined,
    };
  },
  computed: {
        ...mapState(['evolbeUser', 'preferences'])
  },
  methods: {
      ...mapActions(['updatePassword', 'updateUser', 'logout']),

    changePassword(){
      this.updatePassword(this.evolbeUser.profile.email);
      this.$toast.add({
        severity:'success',
        summary: 'Se ha enviado un mensaje a tu email para cambiar la contraseña',
        detail:'No olvides revisar también la bandeja de SPAM',
        group: 'br',
        life: 5000
      });
    },

    updateDevices(){
      BluetoothSerial.list().then(res => {
        this.BTList = res;
      });
    },

    toggleConection(device){
      if(!this.btPrinter){
          this.btPrinter = device;
      } else {
          this.btPrinter = undefined;
      }
    },

    save(){
      this.updateUser(this.evolbeUser);
      this.preferences.printType = this.printType;
      this.preferences.paperSize = this.paperSize;
      if(this.preferences.printType === 'bluetooth'){
        this.preferences.btPrinter = this.btPrinter;
      }
      localStorage.setItem('preferences', JSON.stringify(this.preferences));
      this.exit();
    },

    exit(){
        this.$router.push('/');
    },
  },
  beforeMount(){
    const preferences = JSON.parse(localStorage.getItem('preferences'));
    if(preferences){
      if(preferences.printType){
        this.printType = preferences.printType;
      }

      if(preferences.paperSize){
        this.paperSize = preferences.paperSize;
      }

      if(preferences.btPrinter){
        this.btPrinter = preferences.btPrinter;
      }
    }

    BluetoothSerial.list().then(res => {
      this.BTList = res;
    });
  },
  setup() {
    return { saveOutline, trashOutline, arrowBackOutline, lockClosedOutline, logOutOutline };
  }
});
</script>

<style scoped>
  .edit-title{
    padding-bottom: 1rem;
  }

</style>